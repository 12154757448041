/*
 * @Description: 路由
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-14 19:40:15
 * @FilePath: /JinChanTuoKe-Front/src/router/index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import { getCookie } from "../utils/common";
// eslint-disable-next-line no-unused-vars
import menuData from "../utils/menu";
// import store from "@/store/index";

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/views/login/index.vue"),
  },
  {
    path: "/agent",
    name: "Agent",
    component: () => import("@/pages/views/login/agent.vue"),
  },
  {
    path: "/",
    name: "Index",
    component: () => import("@/pages/views/home/index.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/pages/views/home/privacy.vue"),
  },
  ...menuData.clientRouter,
  ...menuData.serviceRouter,
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return { x: 0, y: 0 };
    }
  },
});

// eslint-disable-next-line no-unused-vars
// const routerState = getCookie("router_state");

// router.beforeEach(async (to, from, next) => {
//   if (store && store.state.routerNav.length === 0) {
//     let routerData = [];
//     if (routerState === "customer") {
//       routerData = menuData.clientRouter;
//     } else if (routerState === "operation") {
//       routerData = menuData.serviceRouter;
//     }
//     if (routerData.length) {
//       //添加路由
//       // router.addRoutes(routerData);
//       //缓存路由
//       store.commit("addRouterData", routerData);
//     }

//     const item = routes.find((e) => e.path === to.path);
//     console.log(item);
//     if (item) {
//       next();
//     } else {
//       next({ ...to });
//     }
//   } else {
//     next();
//   }
// });

export default router;
