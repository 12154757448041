/*
 * @Description: 公共mixins
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-21 22:06:58
 * @FilePath: /golden_cicada/src/utils/mixins/common.js
 */
export default {
  data() {
    return {
      isLoading: false,
      page: {
        // 分页全局混入
        total: 0,
        size: 20,
        page: 1,
      },
    };
  },
};
