/*
 * @Description: 配置路由
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 23:04:33
 * @FilePath: /JinChanTuoKe-Front/src/utils/menu.js
 */

export const clientRouter = [
  {
    path: "/index",
    name: "Views",
    component: () => import("@/pages/basic/index.vue"),
    redirect: "/staging",
    children: [
      {
        path: "/staging",
        name: "Staging",
        component: () => import("@/pages/views/staging/index.vue"),
      },
      {
        path: "/common",
        name: "Common",
        component: () => import("@/pages/views/staging/common.vue"),
      },
      {
        path: "/home",
        name: "Home",
        component: () => import("@/pages/views/staging/home.vue"),
      },
      {
        path: "/authentication",
        name: "Authentication",
        component: () => import("@/pages/views/account/authentication.vue"),
      },
      {
        path: "/binding",
        name: "Binding",
        component: () => import("@/pages/views/account/binding.vue"),
      },
      {
        path: "/recommendation",
        name: "Recommendation",
        component: () => import("@/pages/views/account/recommendation.vue"),
      },
      {
        path: "/flowPath",
        name: "FlowPath",
        component: () => import("@/pages/views/content/flowPath.vue"),
      },
      {
        path: "/sourceMaterial",
        name: "SourceMaterial",
        component: () =>
          import("@/pages/views/content/sourceMaterial/index.vue"),
      },
      // {
      //   path: "/frequenceKwd",
      //   name: "FrequenceKwd",
      //   component: () =>
      //     import("@/pages/views/content/frequenceKwd/index.vue"),
      // },    
      // {
      //   path: "/customInfo",
      //   name: "CustomInfo",
      //   component: () => import("@/pages/views/content/customInfo/index.vue"),
      // },          
      {
        path: "/resourceLibrary",
        name: "ResourceLibrary",
        component: () =>
          import("@/pages/views/content/resourceLibrary/index.vue"),
      },
      {
        path: "/brandLibrary",
        name: "BrandLibrary",
        component: () => import("@/pages/views/content/brandLibrary/index.vue"),
      },
      // {
      //   path: "/subtitleLibrary",
      //   name: "SubtitleLibrary",
      //   component: () => import("@/pages/views/content/subtitleLibrary/index.vue"),
      // },      
      {
        path: "/screening",
        name: "Screening",
        component: () => import("@/pages/views/data/screening/index.vue"),
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("@/pages/views/data/account/index.vue"),
      },
      {
        path: "/ranking",
        name: "Ranking",
        component: () => import("@/pages/views/data/ranking/index.vue"),
      },
      {
        path: "/user-account",
        name: "UserAccount",
        component: () => import("@/pages/views/user/account.vue"),
      },
      {
        path: "/message",
        name: "Message",
        component: () => import("@/pages/views/user/message.vue"),
      },
      {
        path: "/order",
        name: "Order",
        component: () => import("@/pages/views/user/order/index.vue"),
      },
      {
        path: "/sale",
        name: "Sale",
        component: () => import("@/pages/views/thread/sale/index.vue"),
      },
      {
        path: "/collect",
        name: "Collect",
        component: () => import("@/pages/views/thread/collect/index.vue"),
      },
    ],
  },
];

export const serviceRouter = [
  {
    path: "/service",
    name: "Service",
    component: () => import("@/pages/basic/index.vue"),
    redirect: "/clue",
    children: [
      {
        path: "/clue",
        name: "Clue",
        component: () => import("@/pages/views/custom/clue/index.vue"),
      },
      {
        path: "/service",
        name: "Service",
        component: () => import("@/pages/views/custom/service/index.vue"),
      },
      {
        path: "/release",
        name: "Release",
        component: () => import("@/pages/views/custom/release/index.vue"),
      },
      {
        path: "/keyword",
        name: "Keyword",
        component: () => import("@/pages/views/custom/keyword/index.vue"),
      },
      {
        path: "/manage",
        name: "Manage",
        component: () => import("@/pages/views/custom/manage/index.vue"),
      },
      {
        path: "/examine",
        name: "Examine",
        component: () => import("@/pages/views/custom/examine/index.vue"),
      },
      {
        path: "/negativeWords",
        name: "NegativeWords",
        component: () =>
          import("@/pages/views/custom/examine/mods/negativeWords.vue"),
      },
      {
        path: "/coreWords",
        name: "CoreWords",
        component: () =>
          import("@/pages/views/custom/examine/mods/coreWords.vue"),
      },
      {
        path: "/images",
        name: "Images",
        component: () => import("@/pages/views/custom/examine/mods/images.vue"),
      },
      {
        path: "/videos",
        name: "Videos",
        component: () => import("@/pages/views/custom/examine/mods/videos.vue"),
      },
      {
        path: "/seedKeyWords",
        name: "SeedKeyWords",
        component: () => import("@/pages/views/custom/examine/mods/seedKeyWords.vue"),
      },
      {
        path: "/frequentKeyWords",
        name: "FrequentKeyWords",
        component: () => import("@/pages/views/custom/examine/mods/frequentWords.vue"),
      },           
      {
        path: "/message_service",
        name: "messageService",
        component: () => import("@/pages/views/user/message_service.vue"),
      },
    ],
  },
];

export const clientList = [
  {
    index: "staging",
    name: "工作台",
    icon: "staging",
    activeIcon: "staging_active",
    subList: [
      {
        index: "common",
        name: "常见问题",
      },
    ],
  },
  {
    index: "2",
    name: "账号管理",
    icon: "account",
    activeIcon: "account_active",
    childList: [
      {
        index: "authentication",
        name: "账号认证",
      },
      {
        index: "binding",
        name: "账号绑定",
      },
      {
        index: "recommendation",
        name: "账号建议",
      },
    ],
  },
  {
    index: "3",
    name: "内容管理",
    icon: "content",
    activeIcon: "content_active",
    childList: [
      {
        index: "flowPath",
        name: "流程概览",
      },
      // {
      //   index: "sourceMaterial",
      //   name: "素材管理",
      // },
      {
        index: "resourceLibrary",
        name: "资源库",
      },
      {
        index: "brandLibrary",
        name: "品牌库",
      },
      // {
      //   index: "subtitleLibrary",
      //   name: "字幕库",
      // },
      // {
      //   index: "customInfo",
      //   name: "自定义信息",
      // },
      // {
      //   index: "frequenceKwd",
      //   name: "高频关键词",
      // },      
    ],
  },
  {
    index: "4",
    name: "线索管理",
    icon: "sale",
    activeIcon: "sale_active",
    childList: [
      {
        index: "sale",
        name: "线索&互动",
        subList: [
          {
            index: "collect",
            name: "我的收藏",
          },
        ],
      },
    ],
  },
  {
    index: "5",
    name: "数据分析",
    icon: "data",
    activeIcon: "data_active",
    childList: [
      {
        index: "screening",
        name: "数据总览",
      },
      {
        index: "account",
        name: "账号数据",
      },
      {
        index: "ranking",
        name: "视频排名",
      },
    ],
  },
  {
    index: "6",
    name: "我的信息",
    icon: "user",
    activeIcon: "user_active",
    childList: [
      {
        index: "user-account",
        name: "账号",
      },
      {
        index: "order",
        name: "订单",
      },
      {
        index: "message",
        name: "消息",
      },
    ],
  },
];

export const serviceList = [
  {
    index: "1",
    name: "客户管理",
    icon: "clue",
    activeIcon: "clue_active",
    childList: [
      {
        index: "clue",
        name: "客户线索",
      },
      {
        index: "manage",
        name: "客户管理",
      },
      {
        index: "examine",
        name: "素材审核",
        childList: [
          {
            index: "coreWords",
            name: "关键词审核",
          },
          {
            index: "images",
            name: "图片审核",
          },
          {
            index: "videos",
            name: "视频审核",
          },
          {
            index: "seedKeyWords",
            name: "高频词审核",
          },
          {
            index: "frequentKeyWords",
            name: "高频词审核",
          },           
        ],
      },
    ],
  },
  {
    index: "service",
    name: "服务商管理",
    icon: "service",
    activeIcon: "service_active",
  },
  {
    index: "release",
    name: "发布管理",
    icon: "release",
    activeIcon: "release_active",
  },
  {
    index: "keyword",
    name: "关键词排名",
    icon: "keyword",
    activeIcon: "keyword_active",
  },
  {
    index: "message_service",
    name: "消息",
    icon: "message",
    activeIcon: "message_active",
  },
];

export default {
  clientRouter,
  serviceRouter,
};
