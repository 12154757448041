/*
 * @Description: vuex配置
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-27 09:15:55
 * @FilePath: /JinChanTuoKe-Front/src/store/index.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    routerNav: [],
  },
  getters: {},
  mutations: {
    addRouterData(state, data) {
      console.log(data);
      state.routerNav = data;
    },
  },
  actions: {},
  modules: {},
});
export default store;
