import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/element-variables.scss";
import "@/styles/index.scss"; // global css
import "swiper/css/swiper.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import commonMix from "@/utils/mixins/common";

// 分页组件
import Pagination from "@/components/Pagination";

// 全局版本号
import Filings from "./components/filings";

Vue.config.productionTip = false;

// 全局组件挂载
Vue.component("Pagination", Pagination);
Vue.component("Filings", Filings);

// 全局混入
Vue.mixin(commonMix);

Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
