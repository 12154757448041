<!--
 * @Description: 备案
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-12-11 21:28:24
 * @FilePath: /JinChanTuoKe-Front/src/components/filings/index.vue
-->
<template>
  <div class="content-foot-text">
    <span @click="handleLink">浙ICP备2021037085号-4</span> ©
    杭州云鼎汇富科技有限公司
  </div>
</template>

<script>
export default {
  methods: {
    handleLink() {
      window.open("https://beian.miit.gov.cn");
    },
  },
};
</script>

<style scoped lang="scss">
.content-foot-text {
  //   border-top: 1px solid #eee;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #61646c;
  text-align: center;
  background-color: #f7fafc;
  span {
    cursor: pointer;
    margin-left: 30px;
    &:hover {
      color: #0066fe;
    }
  }
}
</style>
