<template>
  <div id="app">
    <router-view />
    <!-- <BasicView /> -->
  </div>
</template>

<script>
// import BasicView from "./pages/basic/index.vue";

export default {
  name: "App",
  mounted() {
    console.log(456);
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  background-color: #f8f8fa;
}

.app-wrapper {
  background-color: #fff;
  box-shadow: 0px 4px 12px 1px rgba(218, 218, 218, 0.28);
  border-radius: 5px;
}
input::placeholder,
textarea::placeholder {
  color: #999999;
}
img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>
